@import "variables";
// includes
@import "includes/helpers";
@import "includes/normalize";
@import "includes/fonts";
@import "../../node_modules/magnific-popup/dist/magnific-popup";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";

html {
    font-size: 16px;
    line-height: 1.2;
    overflow-x: hidden;
}

body {
    background-color: $black;
    color: $white;
    font-family: $primary-font;
    font-weight: normal;
    font-size: rem(16);
    line-height: 1.2;
    overflow: hidden;
}

a{
    color: inherit;
    transition: .3s all;
    @include hover{
        color: $primary-color;
    }
}

.container{
    max-width: rem(1600);
}


form{
    background-color: $black;
    color: $primary-color;
    padding: rem(140) rem(10);
    p{
        font-size: rem(20);
    }
    input{
        text-align: center;
        display: block; 
        margin: rem(20) auto;
        background: none;
        border: none;
        border-bottom: 1px solid $primary-color;
        color: $primary-color;
        &::placeholder{
            color: $primary-color; 
        }
    }
    button{
        transition: .3s all;
        display: block;
        width: rem(120);
        max-width: 100%;
        margin: rem(20) auto;
        background-color: $black;
        color: $primary-color;
        display: inline-block;
        padding: rem(5) rem(10);
        box-shadow: 2px 2px 0 #000, -2px -2px 0px #000,4px 4px 0 #E987B6, -4px -4px 0px #99BCDE;
        text-decoration: none;
        margin: rem(10);
        font-size: rem(20);
        @include hover{
            box-shadow: -2px -2px 0 #000, 2px 2px 0px #000,-4px -4px 0 #E987B6, 4px 4px 0px #99BCDE;
        }
    }
}

#main{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    min-height: 100vh;
    #page{
        position: relative;
        min-height: rem(600);
        flex: 1;

        >video{
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            &.mobile{
                display: none;
            }
            @media screen and (max-width: 900px){
                &.desktop{
                    display: none;
                }
                &.mobile{
                    display: block;
                }
            }
        }

        #screen{
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            overflow: hidden;
            
        }
        .icon{
            display: inline-block;
            text-decoration: none;
            text-align: center;
            position: absolute;
            width: rem(100);
            transition: .3s color;
            &.hide{
                opacity: 0;
                pointer-events: none;
            }
            span{
                display: block;
                font-weight: bold;
                font-family: $bold-font;
                text-transform: uppercase;
            }
            &:nth-child(1){
                img{
                    width: rem(80);
                }
                top: rem(30);
                left: calc(100% - 120px);
            }
            &:nth-child(2){
                top: rem(140);
                left: calc(100% - 120px);
            }
            // &:nth-child(3){
            //     top: rem(340);
            //     left: calc(100% - 120px);
            //     display: none;
            // }
            &:nth-child(3){
                width: rem(120);
                top: rem(20);
                left: rem(50);
                img{
                    width: rem(100);
                }
                @media screen and (max-width: 900px){
                    left: rem(20);
                }
            }
            &:nth-child(4){
                top: rem(150);
                left: rem(60);
                @media screen and (max-width: 900px){
                    left: rem(30);
                }
            }
            &:nth-child(5){
                width: rem(120);
                top: rem(290);
                left: rem(50);
                img{
                    width: rem(100);
                }
                @media screen and (max-width: 900px){
                    left: rem(20);
                }
            }

            &:nth-child(6){
                top: rem(400);
                left: rem(60);
                @media screen and (max-width: 900px){
                    left: rem(30);
                }
            }

            &:nth-child(7){
                top: calc(100% - 80px);
                left: calc(100% - 100px);
            }

            &:nth-child(8){
                top: calc(100% - 100px);
                left: calc(100% - 160px);
            }
            &:nth-child(9){
                top: calc(100% - 100px);
                left: calc(100% - 240px);
            }
            &:nth-child(10){
                top: calc(100% - 100px);
                left: calc(100% - 320px);
            }

        }
    }
    #menu{
        background-image: linear-gradient(180deg, #FFD9EB 0%, #E987B6 67%, #E987B6 100%);
        background-size: cover;
        min-height: rem(65);
        display: flex;
        position: relative;
        z-index: 3;
        h1{
            padding: 0 rem(30);
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            img,a{
                display: block;
            }
            a{
                @include hover{
                    opacity: .6;
                }
            }
        }
        
        .menu-wrap{
            align-self: stretch;
            flex: 1;
            display: flex;
            @media screen and (max-width: 900px){
                position: absolute;
                bottom: 100%;
                left: 0;
                width: 100%;
                display: block;
                opacity: 0;
                pointer-events: none;
                &.open{
                    opacity: 1;
                    pointer-events: all;
                }
                .menu-btn{
                    margin: rem(10) 0;
                }
            }
        }
        .menu-btn{
            background-image: linear-gradient(180deg, #FFD9EB 0%, #E987B6 67%, #E987B6 100%);
            background-size: cover;
            display: block;
            flex: 1;
            padding: rem(20) rem(30);
            margin: 0 rem(5);
            border: 1px solid $gray;
            text-transform: uppercase;
            font-size: rem(24);
            text-align: center;
            text-decoration: none;
        }

        @media screen and (min-width: 901px){
            .menu-toggle{
                display: none;
            }
        }
    }
}


.popup{
    position: absolute;
    width: rem(700);
    max-width: 100%;
    color: $black;
    background-color: #E3E3E3;
    padding: rem(5);
    text-align: center;
    transition: opacity .2s;
    @media screen and (max-width: 900px){
        width: rem(900);
        top: rem(30) !important;
        left: 0 !important;
        &#contest, &#cta{
            top: rem(80) !important;
            max-width: 80%;
            left: 10% !important;
        }
    }
    .bar{
        background-image: linear-gradient(180deg, #FFD9EB 0%, #E987B6 67%, #E987B6 100%);
        background-size: 100% 100%;
        box-shadow: 2px 2px 0 #000,0 2px 0 #000,2px 0 0 #000, -2px -2px 0px #fff, -2px 0 0px #fff, -0 -2px 0px #fff;
        padding: rem(3);
        display: flex;
        align-items: center;
        text-align: left;
        color: $white;
        font-weight: bold;
        font-family: $bold-font;
        letter-spacing: .1em;
        text-transform: uppercase;
        span{
            flex: 1;
        }
        >img{
            flex-basis: rem(30);
            object-fit: stretch;
            object-fit: contain;
            object-position: center left;
            height: rem(24);
        }
    }
    .wrap{
        display: flex;
        padding: rem(10) 0;
        align-items: center;
        @media screen and (max-width: 900px) {
            flex-direction: column;
        }
        .buttons{
            flex: 1;
            text-transform: uppercase;
            max-width: 100%;
            a{
                background-color: $white;
                color: $black;
                display: inline-block;
                padding: rem(5) rem(10);
                box-shadow: 2px 2px 0 #000, -2px -2px 0px #E3E3E3,4px 4px 0 #E987B6, -4px -4px 0px #99BCDE;
                text-decoration: none;
                margin: rem(10);
                i, span{
                    font-size: rem(20);
                    display: inline-block;
                    vertical-align: middle;

                }
                @include hover{
                    box-shadow: -2px -2px 0 #000, 2px 2px 0px #E3E3E3,-4px -4px 0 #E987B6, 4px 4px 0px #99BCDE;
                }
            }
            p{
                font-size: rem(26);
                margin: 0;
                
                @media screen and (max-width: 900px) {
                    font-size: rem(18);
                }
            }
            .note{
                font-size: rem(8);
                width: rem(300);
                max-width: 100%;
                margin: 0 auto;
                padding: rem(10);
                font-family: sans-serif;
                a{
                    display: inline;
                    text-decoration: underline;
                    background: none;
                    box-shadow: none;
                    @include hover{
                        color: $primary-color;
                    }
                }
            }
        }
    }
    &.popup-hide{
        opacity: 0;
        pointer-events: none;
    }

    .content{
        margin-top: rem(10);
        background-color: #FFD9EB;
        padding: rem(15) rem(10);
        box-shadow: -2px -2px 0 #000,-2px 0 0 #000,0 -2px 0 #000, 2px 2px 0px #fff,0 2px 0px #fff,2px 0 0px #fff;
        .black{
            padding: rem(120) rem(30);
            background-color: black;
            color: $white;
            .big{
                margin: 0;
                font-family: $bold-font;
                font-size: rem(55);
                text-transform: uppercase;
            }
            .social{
                text-align: center;
                a{
                    display: inline-block;
                    margin: rem(10);
                }
            }
        }
        
    }
}
#intro{
    width: rem(500);
    top: 2%;
    left: 25%;
    @media screen and (max-width: 900px){
        .desktop{
            display: none;
        }
    }
    >div:last-child{
    //     padding: rem(30);
    //     font-family: $bold-font;
    //     @media screen and (max-width: 1000px){
    //         padding: rem(15);   
    //     }

    //     img{
    //         display: block;
    //         margin: rem(20) auto;
    //     }
    //     .cta{
    //         font-size: rem(24);
    //         text-transform: uppercase;
    //         letter-spacing: .05em;
    //     }
    //     .tracks{
    //         font-size: rem(16);
    //         letter-spacing: .05em;
    //         width: 100%;
    //         line-height: 1.8;
            
    //         @media screen and (max-width: 1000px){
    //             font-size: rem(14);
    //             line-height: 1.2;
    //         }
    //         span{
    //             display: inline-block;
    //             text-transform: uppercase;
    //             &::after{
    //                 content: ' /';
    //             }
    //             &:nth-child(1),&:nth-child(6),&:nth-child(7),&:nth-child(13){
    //                 color: #75acde;
    //             }
    //             &:nth-child(2),&:nth-child(5),&:nth-child(8),&:nth-child(9),&:nth-child(10),&:nth-child(12){
    //                 color: $primary-color;
    //             }
    //             &:nth-child(3),&:nth-child(4),&:nth-child(10),&:nth-child(11){
    //                 color: #76a654;
    //             } 
    //             &:last-child::after{
    //                 content: none;
    //             }
    //         }
    //     }
    
        @media screen and (max-width: 900px){
            .desktop{
                display: none;
            }
        }

        &.tourinfo{
            padding: 0;
            position: relative;
            img{
                display: block;
                width: 100%;
                max-width: rem(690);
                margin: 0 auto;
            }
            .btns{
                background: #b1bccc;
                .btn{
                    span{ 
                        font-size: 1.25rem;
                    }
                    @media screen and (max-width: 501px){
                        padding: rem(1) rem(10) !important;
                        margin: rem(5);
                    }
                }
            }
            
            @media screen and (min-width: 681px){
                
            }
        }

        .btn{
            background-color: $white;
            color: $black;
            display: inline-block;
            padding: rem(5) rem(40);
            box-shadow: 2px 2px 0 #000, -2px -2px 0px #E3E3E3,4px 4px 0 #E987B6, -4px -4px 0px #99BCDE;
            text-decoration: none;
            margin: rem(10);
            i, span{
                font-size: rem(24);
                display: inline-block;
                vertical-align: middle;
                text-transform: uppercase;

            }
            @include hover{
                box-shadow: -2px -2px 0 #000, 2px 2px 0px #E3E3E3,-4px -4px 0 #E987B6, 4px 4px 0px #99BCDE;
            }
        }
    }
}

#presave{
    top: 25%;
    left: 25%;
    
    .close-btn{
        
        font-size: rem(18);
        text-transform: uppercase;
    }
    .wrap{
        padding: rem(40) rem(10);
        .img{
            flex-basis: rem(200);
            img{
                display: block;
                width: 100%;
            }
        }
    }
    .wrap:nth-child(3){
        border-top: 3px solid $black;
        justify-content: space-between;
        padding: rem(40) rem(30);
        @media screen and (min-width: 900px){
            >.close-btn{
                display: none;
            }
        }
        
        >div{
            @media screen and (max-width: 900px){
                .close-btn{
                    display: none;
                }
            }
            &:nth-child(1){
                text-align: left;
                text-transform: uppercase;
                p{
                    font-size: rem(26);
                    margin: 0;
                }
            }
            &:nth-child(2){
                display: flex;
            }
        }
        .timer{
            position: relative;
            span{
                display: block;
                font-size: rem(39);
                &:last-child{
                    font-size: rem(8);
                    text-transform: uppercase
                }
            };
            &+.timer{
                margin-left: rem(15);
                &:before{
                    content: ':';
                    position: absolute;
                    left: rem(-12);
                    top: rem(-4);
                    font-size: rem(39);
                }
            }
        }
    }
}

#mailinglist{
    left: rem(30);
    top: rem(100);
}
#trailer{
    left: rem(60);
    top: rem(260);
}

#video{
    left: rem(70);
    top: rem(290);
}

@keyframes shake{
    0%{
        transform: translateX(0);
    }
    10%{
        transform: translateX(10%);
    }
    30%{
        transform: translateX(-10%);
    }
    50%{
        transform: translateX(10%);
    }
    70%{
        transform: translateX(-10%);
    }
    90%{
        transform: translateX(10%);
    }
    10%{
        transform: translateX(0);
    }
}

#quiz{
    left: rem(100);
    top: rem(20);
    .content{
        background-color: $black;
        color: $white;
        font-family: $bold-font;
        padding-bottom: rem(40);
    }

    @media screen and (min-width: 1001px){
        .mobile{
            display: none !important;
        }    
    }
    @media screen and (max-width: 1000px){
        .desktop{
            display: none !important;
        }    
    }

    .saves{
        margin-top: rem(-30);
        p{
            margin: 0;
            font-size: rem(24);
            text-transform: uppercase;
        }

        .btn{
            padding:  rem(5) rem(10);
        }
    }
    

    #result{
        overflow: hidden;
        >div{
            display: none;
        }
        img{
            width: rem(360);
            display: block;
            margin: 0 auto;
            
            @media screen and (max-width: 1000px){
                width: rem(300);
                margin-top: rem(-30);
            }
        }

        .link{
            text-transform: uppercase;
            display: inline-block;
            text-decoration: none;
            font-size: rem(30);
            border-bottom: rem(3) solid $white;
            @include hover{
                border-color: $primary-color;
            }
        }
    }

    .question{
        display: none;
        .q{
            font-size: rem(50);
            text-transform: uppercase;
            @media screen and (max-width: 1000px){
                margin: 0 0 1em;
                font-size: rem(30);
            }
            span{
                display: inline-block;
            }
        }
        label{
            font-size: rem(30);
            margin-bottom: rem(15);
            display: block;
            text-align: left;
            padding-left: rem(35);
            position: relative;
            
            @media screen and (max-width: 1000px){
                font-size: rem(24);
            }
            input::before{
                content: '';
                width: rem(30);
                height: rem(30);
                border: 1px solid $primary-color;
                position: absolute;
                background-color: $black;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                transition: .3s all;
            }
            input:checked::before{
                background-color: $primary-color;
            }
            input{
                appearance: none;
            }
        }
    }

    .btn{
        background-color: #e3e3e3;
        color: $black;
        display: inline-block;
        padding: rem(5) rem(40);
        box-shadow: 2px 2px 0 #000, -2px -2px 0px #000,4px 4px 0 #E987B6, -4px -4px 0px #99BCDE;
        text-decoration: none;
        margin: rem(10);
        i, span{
            font-size: rem(24);
            display: inline-block;
            vertical-align: middle;
            text-transform: uppercase;
        }
        @include hover{
            box-shadow: -2px -2px 0 #000, 2px 2px 0px #000,-4px -4px 0 #E987B6, 4px 4px 0px #99BCDE;
        }
        &.shake{
            animation: forwards .3s shake;
        }
    }
}

#outnow{
    left: rem(100);
    top: rem(120);
    .content{
        background-color: $black;
    }
}
#connect{
    top: rem(70);
    left: 30%;
}
#merch{
    top: rem(150);
    left: rem(200);
    .content .black{
        padding: rem(40) rem(30) rem(120);
    }
}

#tour{
    left: rem(350);
    top: rem(110);
    #events{
        background-color: $black;
        color: $primary-color;
        text-align: left;
        font-size: rem(20);
        height: rem(500);
        padding: rem(35) 0;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 5px;
        }
           
        &::-webkit-scrollbar-thumb {
            background: #666; 
        }
        .track-cta{
            display: none;
        }
        h2{
            font-weight: normal;
            text-transform: uppercase;
            padding: rem(10) rem(16);
            margin: 0 0 rem(20); 
            color: $primary-color;
        }

        .follow-btn{
            font-family: sans-serif;
            display: block;
            width: max-content;
            margin: 0 auto;
            font-size: rem(16);
            border: 1px solid $primary-color;
            padding: rem(4) rem(8);
            text-decoration: none;
            border-radius: rem(5);
            @include hover{
                color: $black;
                background-color: $primary-color;
            }
        }

        #shows{
            font-family: sans-serif;
            .event{
                font-size: rem(16);
                padding: rem(10) rem(16);
                display: flex;
                justify-content: space-between;
                @media screen and (max-width: 550px){
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    .event-links{
                        margin-top: rem(10);
                    }
                }
                .event-date{
                    font-weight: bold;
                }
                .btn{
                    display: inline-block;
                    border: 1px solid $primary-color;
                    padding: rem(4) rem(8);
                    text-decoration: none;
                    border-radius: rem(5);
                    &+.btn{
                        margin-left: rem(10);
                    }
                    @include hover{
                        color: $black;
                        background-color: $primary-color;
                    }
                }
                &+.event{
                    border-top: 1px solid #888A;
                }
            }
        }
    }
}

#thankyou{
    left: rem(340);
    top: rem(200);
    width: rem(500);
    z-index: 20;
    .content{
        font-size: rem(20);
        background-color: #E3E3E3;
    }
}

#contest{
    left: rem(20);
    top: rem(20);
    width: rem(350);
    form{
        padding: rem(40) 0;
        p{
            padding: rem(10);
        }
    }

    .note{
        font-size: rem(10);
        width: rem(300);
        max-width: 100%;
        margin: 0 auto;
        padding: rem(10);
        font-family: sans-serif;
        a{
            display: inline;
            text-decoration: underline;
            background: none;
            box-shadow: none;
            @include hover{
                color: $primary-color;
            }
        }
    }
}

#cta{
    border: 1px solid white;
    left: rem(40); 
    top: rem(40);
    width: rem(350);
    z-index: 20;
    .content{
        background-color:#e3e3e3;
        box-shadow: none;
        h2{
            margin-top: 0
        }
        img{
            display: inline-block;
            margin: rem(4);
        }
    }
    .note{
        font-size: rem(10);
        width: rem(300);
        max-width: 100%;
        margin: 0 auto;
        padding: rem(10);
        font-family: sans-serif;
        a{
            display: inline;
            text-decoration: underline;
            background: none;
            box-shadow: none;
            @include hover{
                color: $primary-color;
            }
        }
    }
}

.chatpopup{
    left: rem(200);
    top: rem(160);
    .wrap{
        justify-content: space-between;
        align-items: stretch;
        margin-bottom: 0;
        >div{
            background-color: $black;
            padding: rem(15);
            &+div{
                margin-left: rem(10);
                @media screen and (max-width: 900px){
                    margin: rem(10) 0 0;
                }
            }
        }
        >div:nth-child(2){
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;

        }
        
        >div:nth-child(1){
            padding: 0;
        }
        >div:nth-child(3), >div:nth-child(1){
            width: rem(100);
            height: rem(100);
            video{
                display: block;
                margin: 0 auto;
                width: rem(100);
                height: rem(100);
                object-fit: cover;
                object-position: center;
            }
            @media screen and (max-width: 900px){
                width: 100%;
            }
        }
    }
    &#chatparty{
        color: #76a654;
    }
    &#chatplay{
        color: #75acde;
    }
    &#chatdream{
        color: $primary-color;
    }
}
.copyright{
    font-family: sans-serif;
    margin: 0;
    padding: rem(5);
    font-size: rem(10);
    text-transform: uppercase;
    text-align: center;
}