//settings
$fa-font-path: "../font";

// Colors //
$black: #000;
$white: #fff;
$primary-color: #E987B6;
$gray: #707070;

//Fonts
//"MynorBook""MynorLight""MynorLightItalic""MynorBookItalic";
$primary-font: "MynorLightCompact", sans-serif;
$bold-font: "MynorBoldCompact", sans-serif;
